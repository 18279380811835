$(document).ready(function() {
    var forceLogout = $("#forceLogout").val();
    var logoutURL = $("#logoutURL").val();
    if(forceLogout === "true" && logoutURL.length > 0){
        signOut(logoutURL);
    }
	var questionInputs = $(".question-wrapper_1 > .checktest input[type='radio']");
	var qnaButtons = $(".qna-btn");
	var qnaBody = $(".qnaBody");
	var questionBox = $(".question-wrapper_1");
	var currentQuestion = 0;
	// Declare RAC and Google global data layer objects and namespace
	  window.RAC = window.RAC || {}; // RAC code namespace to keep from cluttering global namespace with methods and variables
	  window.dataLayer = window.dataLayer || []; // Google global dataLayer object (can be declared and events pushed even before GA/GTM initialize)
	  // Meta viewport tags
      var devicePhysicalWidth = window.devicePixelRatio === 3 ? 414 : 375;
      devicePhysicalWidth =
        window.devicePixelRatio === 2.625 ? 412 : devicePhysicalWidth;
      var deviceWidthRatio = 320 / devicePhysicalWidth;
      if (deviceWidthRatio < 0.77) {
        deviceWidthRatio = 0.77;
      }
      if (deviceWidthRatio > 1) {
        deviceWidthRatio = 1;
      }

	$(questionBox).each(function (k, v) {
		$(v).click(function () {
			let indexKey = $(this).parent().parent().data("qnabody");
			$(qnaButtons[indexKey]).prop('disabled', false)
		})
	});
	$(qnaButtons).each(function (k, v) {
		$(v).click(function () {
			if (currentQuestion < 2) {
				$(qnaBody[currentQuestion]).addClass("d-none");
				$(qnaButtons[currentQuestion]).addClass("d-none");
				currentQuestion = currentQuestion + 1;
				$(qnaBody[currentQuestion]).removeClass("d-none");
				$(qnaButtons[currentQuestion]).removeClass("d-none");
			}
		});
	});

      if (
        navigator.userAgent.indexOf("Cordova") !== -1 &&
        navigator.userAgent.indexOf("iPhone") !== -1
      ) {
        viewport = document.querySelector("meta[name=viewport]");
        var str =
          "minimum-scale=" +
          deviceWidthRatio +
          ", maximum-scale=" +
          deviceWidthRatio +
          ", initial-scale=" +
          deviceWidthRatio;
        viewport.setAttribute("content", str);
      }
      
      
      !function(){var t=function(){var t=document.createElement("script");t.src="https://ws.audioeye.com/ae.js",t.type="text/javascript",t.setAttribute("defer",""),document.getElementsByTagName("body")[0].appendChild(t)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",t):window.attachEvent&&window.attachEvent("onload",t):t()}();

	//Vanity URL
	getUrlParameter = function (name) {
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		return results ? (decodeURI(results[1]) || 0) : null;
	}

	var BrowserRefreshHandler = function (flag) {
		// setting default value
		this.flag = flag;
		this.setFlag = function (flag) {
			this.flag = flag;
		}
		var returnFn = function () {
			if (flag) {
				return "";
			}
		}
		return returnFn;
	};

	window.switchBrowserRefreshWarning = function (flag) {
		window.onbeforeunload = new BrowserRefreshHandler(flag)
	};

	window.redirectLinkClick = function (href, skipBrowserRefreshWarning) {
		if (skipBrowserRefreshWarning) {
			window.switchBrowserRefreshWarning(false);
		}
		// similar behavior as an HTTP redirect
		window.location.replace(href);
	}; 
	
	jQuery.event.special.touchstart = {
	  setup(_, ns, handle) {
	    this.addEventListener('touchstart', handle, {
	      passive: true
	    });
	  }
	};
	
	jQuery.event.special.mousewheel = {
	  setup(_, ns, handle) {
	    this.addEventListener('mousewheel', handle, {
	      passive: true
	    });
	  }
	};

	//Store Change close button
	$(".store-change-close").click(function(){
		$(this).parents(".store-change-box").addClass("d-none");
		$.ajax({
			url: ACC.config.encodedContextPath + '/cart/close-store-change-modal',
			type: "GET",
			async: false,
			success: function() {},
			error: function() {},
			complete: function() {}
		});
	});

	if((window.location.pathname == '/en/myaccount/agreements') || (window.location.pathname == '/es/myaccount/agreements')){
	    $(".submenu_dropdown").addClass("loggedIn");
	}

	$('.rac-exchange-owl-carousel').owlCarousel({
		loop:false,
		margin:20,
		responsive:{
			0:{
				items:1
			},
			525:{
				items:2
			},
			880:{
				items:3
			}
		}
	})
});

// Check if an element is on viewport or no
$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
};

function signOut(idpLogoutURL) {
	document.cookie = "rac-cart=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
	document.cookie = "rac-wishlist=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
	deleteCookie("sf_chat_initiate");
	window.location.href = idpLogoutURL;
}

function submitFCRAConsent(){
		$.ajax({
			url: ACC.config.encodedContextPath + '/set-fcra-consent',
			type: "post",
			async: true,
			contentType: 'application/json'
		})
	}

// Generic Utility Functions
function isElemExist(elem) {
	return $(elem) !== null && $(elem) !== undefined && $(elem).length > 0;
}
function isNullUndefined(val) {
	return typeof val == 'string' && !val.trim() || val === undefined || val === 'undefined' || val === null;
}
function isTrue(val) {
	return (val !== null || val !== undefined) && val !== '' && (val === true || val === 'true' || val === 'YES' || val === 'Yes');
}
function isFalse(val) {
	return (val !== null || val !== undefined) && val !== '' && (val === false || val === 'false' || val === 'NO' || val === 'No');
}
function isNotTrue(val) {
	return val !== true ?  true :  false;
}
function isNotFalse(val) {
	return val !== false ? true : false;
}
function isMobileDevice() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

$(document).ready(function () {
	$(".racExchangeLink").click(function () {
		if (RAC.coreDataLayer.hfLoggedIn === 'Yes') {
			$("#headerLoginContainerSignIn").modal('hide');
			window.location.href = ACC.config.encodedContextPath + '/myaccount/agreements';
		} else {
			$(".signInLoading").hide();
			$("#oktaSignin").empty().append('<iframe src="/racsso/saml/" width="100%" scrolling="auto" id="signInIframe" height="480px"></iframe>');
			$("#headerLoginContainerSignIn").modal('show');
		}
	});
});
